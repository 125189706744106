<template>
  <div class="flex items-center mt-2">
    <div class="flex items-center w-56 select-none text-sm leading-tight">
      {{ filter.label }}
    </div>
    <resource-select
      class="w-full"
      :resource-query="resourceQuery"
      :resource="filter.options.resource"
      :placeholder="filter.placeholder"
      :append-to-body="appendToBody"
      :computed-fields="computedFields"
      :model-value="modelValue"
      :default-key="filter.defaultKey"
      :multiple="filter.multiple"
      @update:modelValue="handleFilterInput"
    />
  </div>
</template>

<script>
import ResourceSelect from '@/components/resource-select'
export default {
  components: {
    ResourceSelect
  },
  props: {
    filter: {
      type: Object,
      required: true
    },
    modelValue: {
      type: [String, Number],
      required: false,
      default: null
    },
    appendToBody: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ['update:modelValue'],
  computed: {
    resourceQuery() {
      const filterQuery = this.filter.resourceQuery || {}
      return { query: { deleted: false, ...filterQuery } }
    },
    computedFields() {
      return this.filter.computedFields || ['name']
    }
  },
  methods: {
    handleFilterInput(v) {
      this.$emit('update:modelValue', v || null)
    }
  }
}
</script>
